import React from 'react'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'
import Layout from '../components/Layout/Layout'
import Hero from '../components/Hero'
import UpcomingEvent from '../components/UpcomingEvent'

const ns = `ndp-page`

const NoahDavisPrizePage = ({ data }) => {
  const { markdownRemark } = data

  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  return (
    <Layout>
      <div className={rootClassnames}>
        <Hero
          hero={{
            urlMobile:
              markdownRemark.frontmatter.hero.hero_image_mobile.publicURL,
            urlDesktop:
              markdownRemark.frontmatter.hero.hero_image_desktop.publicURL,
          }}
        />
        <div className={`${ns}__container`}>
          <h1 className="page--title">{markdownRemark.frontmatter.title}</h1>
          <div className={`${ns}__content-container`}>
            <div className={`${ns}__sections`}>
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {markdownRemark.html}
              </ReactMarkdown>
            </div>
            {markdownRemark.frontmatter.awardees.show_section && (
              <div className={`${ns}__awardees container-fluid`}>
                <h2 className="sub-title">
                  {markdownRemark.frontmatter.awardees.heading}
                </h2>
                <div className="row">
                  {markdownRemark.frontmatter.awardees.winners.map(
                    (winner, index) => (
                      <div className="col-12 col-md-4" key={index}>
                        <img
                          className="img-fluid"
                          src={winner.image.publicURL}
                          alt=""
                        />
                        <p className="bold margin-bottom-0">{winner.name}</p>
                        <p>{winner.location}</p>
                        <ReactMarkdown
                          className="mobile-spacing"
                          rehypePlugins={[rehypeRaw]}
                        >
                          {winner.about}
                        </ReactMarkdown>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
            <div className="container-fluid">
              {markdownRemark.frontmatter.upcoming_events.show_section && (
                <UpcomingEvent
                  eventTitle={
                    markdownRemark.frontmatter.upcoming_events.upcoming_event
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NoahDavisPrizePage

export const noahDavisPrizePageQuery = graphql`
  query NoahDavisPrizePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        hero {
          hero_image_desktop {
            publicURL
          }
          hero_image_mobile {
            publicURL
          }
        }
        title
        awardees {
          show_section
          heading
          winners {
            about
            image {
              publicURL
            }
            location
            name
          }
        }
        upcoming_events {
          show_section
          upcoming_event
        }
      }
    }
  }
`
